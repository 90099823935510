@use '../../styles/constants.scss' as *;

.text-carousel {
  width: 100%;

  .text-item-wrapper {
    @include flex;
  }

  .text-item {
    width: 90%;
  }

  .flickity-page-dots {
    display: none;
  }

  .dot {
    border: 1px solid white;
    background: transparent;
    opacity: 1;
  }

  .dot.is-selected {
    background: white;
  }

  &.show-dots {
    .flickity-page-dots {
      display: block;
    }
  }
}
