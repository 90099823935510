@use '../../styles/constants.scss' as *;

.milestone-experience {
  @include ui;

  font-size: 16px;

  background: rgba(91, 66, 225, 0.65);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(6px);
  border-radius: 12px;
  box-sizing: border-box;

  bottom: 35vw;
  top: auto;
  left: 50%;
  transform: translate(-50%, 0);

  width: 85vw;
  max-width: 400px;

  padding: 1.25em 1.3125em;
  color: $color-white;

  font-family: $typeface-primary;
  font-style: normal;

  scale: 0;
  opacity: 0;
  transform-origin: 0 50%;
  transition: max-height 0.5s;
  max-height: 3.75em;
  overflow: hidden;

  &--visible {
    animation: show 1s forwards;
  }  
  
  &--hidden {
    animation: hide 0.4s forwards;
  } 
  
  &--invisible {
    display: none;
  }

  &--expanded {
    // height: auto;
    max-height: 70vh;

    .title {
      transform: translateY(2.5625em);
    }
  }

  .header {
    display: flex;
    align-items: space-between;
    justify-content: space-between;
    flex-direction: row;
  }

  .button-wrapper {
    position: relative;
  }

  .title {
    font-weight: 600;
    font-size: 1em;
    line-height: 1.5em;
    margin: 0;

    transition: transform 0.3s
  }

  .text {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1.3125em;
    margin-top: 2.8em;

    sup {
      font-size: 0.83em;
      vertical-align: super;
    }
  }

  .carousel-dots {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-top: 0;
    
    li {
      line-height: 0;

      &:not(:last-child) {
        margin-right: 0.9em;
      }
    }

    button {
      font-size: 0;
      background-color: transparent;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transition: background-color 0.5s;
      border: 1px solid $color-white;
      padding: 0;
    }

    .slick-active {
      button {
        background-color: $color-white;
      }
    }
  }

  .divider {
    margin-top: 1.6875em;
    margin-bottom: 1.75em;
    height: 1px;
    background-color: white;
  }

  .button {
    background-color: transparent;
    height: 30px;
    width: 30px;
  }

  .header-button {
    transform: translate(0, -0.3125em);
    height: 30px;
    width: 30px;
    background-color: transparent;
    box-shadow: none;
    top: 0;
    left: 0;
    transition: transform 0.4s;

    img { 
      height: 30px;
      width: 30px;  
    }
  }

  .fold-button {
    position: absolute;
    transform: translate(0, -0.3125em) rotate(180deg)!important;
    
    &--expanded {
      transform: translate(calc(-0.75em - 100%), -0.3125em) rotate(0deg)!important;
    }
  }

  .close-button {
    scale: 1;
    transition: scale 0.5s;

    &--hidden {
      scale: 0;
    }
  }
}
