body, html {
  height: 100%;
  width: 100%;
  margin: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

#root {
  width: 100%;
  height: 100%;
}
