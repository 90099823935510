@use '../../styles/constants.scss' as *;

.rotate-curtain {
  @include ui();
  @include flex(column);

  background-color: $color-black;
  z-index: 995;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  height: 100%;

  img {
    width: 150px;
    margin-bottom: 1rem;
  }

  span {
    color: white;
    text-align: center;
    font-family: $typeface-primary;
    line-height: 1.4;
  }

  @media (orientation: landscape) {
    opacity: 1;
    pointer-events: all;
  }
}
