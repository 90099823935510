@use '../../styles/constants.scss' as *;

.reposition-button {
  // @include ui;
  position: absolute;
  z-index: 1;
  right: 10vmin;
  bottom: 11vmin;
  width: 10vmin;
  height: 10vmin;
  padding: 0;
  background-color: $color-primary;

  img {
    width: 6vmin;
    height: 6vmin;
    margin: 0;
    display: block;
  }

  &--active {
    background-color: $color-white;

    img {
      width: 4vmin;
      height: 4vmin;
    }
  }
}