@use '../../styles/constants.scss' as *;

.on-site-location-button {
  // @include ui;
  position: absolute;
  z-index: 1;
  left: 10vmin;
  bottom: 11vmin;
  width: 10vmin;
  height: 10vmin;
  padding: 0;
  background-color: $color-primary;
  transform: scale(1);
  opacity: 1;
  transition: transform 0.5s;
  animation: show 0.5s;

  img {
    width: 6vmin;
    height: 6vmin;
    margin: 0;
    display: block;
  }

  &--active {
    transform: scale(0) !important;
    opacity: 0;
    transition: transform 0.5s opacity 0.5;
  }
}