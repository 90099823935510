@use '../../styles/constants.scss' as *;

.tap-to-place-overlay {
  @include ui;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 99;
  justify-content: center;
  display: flex;

  img {
    width: 90vw;
    animation: show 0.5s;
    max-width: 450px;
  }
}