@use '../../../styles/constants.scss' as *;

:root {
  --primarycolor: #4b4768;
  --secondarycolor: #3a3c55;
  --backgroundcolor: #12101b;
  --primarybuttoncolor: #ac4fff;
  --secondarybuttoncolor: #8083a2;
  --textcolor: #fcfeff;
  --fontfamily: 'Nunito-SemiBold', sans-serif;
}

#requestingCameraPermissions {
  background-color: var(--primarycolor);
}

div.prompt-box-8w.prompt-box-8w {
  background-color: var(--secondarycolor);
}

#loadingContainer, #loadBackground {
  background-color: var(--backgroundcolor);
}

button.prompt-button-8w.prompt-button-8w {
  background-color: var(--secondarybuttoncolor);
}

button.button-primary-8w.button-primary-8w, .main-button {
  background-color: var(--primarybuttoncolor);
}

#loadingContainer *, .prompt-box-8w * {
  color: var(--textcolor);
  font-family: var(--fontfamily);
}
