@use '../../styles/constants.scss' as *;

.coaching-overlay {
  @include ui;

  font-size: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: show 1s forwards;
  pointer-events: none;

  .gif-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .image-wrapper {
    position: relative;
    width: 100%;
  }

  .image-border {
    padding: 4vw;
    background-image: url('../../assets/coaching-overlay-image-border.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: auto;
    // opacity: 0.85;
    aspect-ratio: 231/308;
    width: 50vw;
    margin-top: 5vh;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .text-wrapper {
    font-size: 1em;
    line-height: 1.5em;
    width: 60vw;
    color: $color-white;
    text-align: center;
    margin-top: 1.2em;
    font-weight: 600;
    margin-bottom: 10vh;
  }
}
