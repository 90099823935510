@use '../../styles/constants.scss' as *;

.nearby-site-message {
  @include ui;

  background: rgba(91, 66, 225, 0.65);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(6px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 12px;

  width: 85vw;

  top: 40%;
  left: 50%;
  padding: 20px;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  max-width: 400px;
  font-size: 0.8rem;

  color: $color-white;

  font-family: $typeface-primary;
  font-style: normal;
  text-align: center;

  scale: 0;
  opacity: 0;
  transform-origin: 0 0;

  &--visible {
    animation: show 1s forwards;
  }

  &--hidden {
    animation: hide 0.4s forwards;
    pointer-events: none;
  }

  .icon {
    width: 15%;
  }

  .subtitle {
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
  }

  .text {
    font-weight: 500;
    line-height: 21px;
  }

  .buttons {
    @include flex(row, space-evenly);

    margin: 1rem 0 1rem;
  }

  .button {
    padding: 10px;
    width: 40%;
    background: $color-white;
    border-radius: 10px;
    border-width: 0;

    font-family: $typeface-primary;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;

    text-align: center;

    color: $color-primary;

    &.tap-to-place {
      color: white;
      background-color: transparent;
      border: 1px solid white;
    }
  }
}
