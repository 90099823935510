@use '../../styles/constants.scss' as *;

@keyframes fadeIn {
  to {
    opacity: 1;
    scale: 1;
  }
}

.toolbar {
  @include flex;
  @include ui;

  box-sizing: border-box;
  justify-content: space-between;
  padding: 5.33vmin;
  z-index: 890;
  pointer-events: none;

  display: none;

  & > * {
    scale: 0;
  }

  &--visible {
    display: flex;
      
    & > * {
      opacity: 0;
      scale: 0;
      animation: fadeIn 0.4s forwards; 
    }
  }
}
