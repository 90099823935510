@mixin flex($direction: row, $justify: center, $align: center, $wrap: nowrap) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
  flex-wrap: $wrap;
}

@mixin ui {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 790;
}

@keyframes show {
  from {
    scale: 0.5;
    opacity: 0;
  }
  to {
    scale: 1;
    opacity: 1;
  }
}

@keyframes hide {
  from {
    scale: 1;
    opacity: 1;
  }
  to {
    scale: 0.5;
    opacity: 0;
  }
}

$typeface-primary: 'Poppins', sans-serif;
$color-primary: #5B42E1;
$color-secondary: #fae251;
$color-black: #161616;
$color-white: #ffffff
