@use '../../styles/constants.scss' as *;

.menu-page.sources {
  position: relative;

  .attribution {
    font-size: 0.8rem;
    padding: 20% 5% 25%;
    position: absolute;
    top: 0;
    text-align: left;
    width: 100%;
    box-sizing: border-box;

    .menu-title {
      margin: 0rem 1.5rem 2rem;
      position: relative;
    }

    p {
      position: relative;
    }

    .backdrop-blur {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      backdrop-filter: blur(22px) brightness(50%);
      mask-image: linear-gradient(rgba(0, 0, 0, 1) 65%, transparent);
    }
  }
  
  .scroller {
    overflow-y: scroll;
    width: 100%;
    margin: 20% 0 0 5%;
  }

  .menu-content {
    padding-top: 1rem;
    padding-bottom: 8rem;
    text-align: left;
    width: 90%;
    margin-left: 2.5%;

    h2 {
      margin: 2.5rem 0 0;
    }

    h3 {
      width: 100%;
      margin: 1.5rem 0 0.5rem;
    }

    p, span, ul {
      width: 100%;
      text-align: left;
    }

    a {
      color: $color-secondary;
    }

    ul {
      margin: 0;
    }

    br {
      margin: 0.5rem;
    }

    .italic {
      font-style: italic;
    }

    .rose-link {
      color: $color-white;
    }

    .credits-title {
      color: $color-primary;
      margin-bottom: 1.3125rem;
    }

    .credits {
      padding: 0;

      li {
        list-style: none;
        padding: 0;
        margin-bottom: 1.3125rem;
      }

      .nowrap {
        white-space: nowrap;
      }
    }
  }
}
