@use '../../styles/constants.scss' as *;

// @include show;
// @include hide;

:root {
  --displaysavebutton: block;
}

html, body {
  font-family: $typeface-primary;
}

#recorder {
  display: none;

  &.visible {
    display: block;
    animation: show 0.5s;
    transform-origin: 0 50%;
  }
}

#requestingCameraPermissions {
  top: 10%;
  font-size: 1rem;
  padding: 5vh 0 2.5vh;
  border: 1px solid white;
  border-width: 1px 0;
}

#requestingCameraIcon {
  height: 4vh;
  width: auto;
}

#loadImageContainer::after, .inner-content::after {
  content: '';
  width: 37.5vw;
  max-width: 225px;
  height: 50px;
  position: fixed;
  bottom: calc(2% + 35px);
  left: 50%;
  transform: translateX(-50%);
  background: url('./assets/builtbyrose.png') bottom/contain no-repeat;
}

.prompt-box-8w {
  font-size: 1rem;
  padding: 1rem 1.5rem 1.5rem;
}

.prompt-button-8w:not(:last-child) {
  margin-right: 1rem;
}

button.prompt-button-8w.prompt-button-8w {
  border: 1px solid white;
  border-radius: 12px;
  padding: 0.7rem 0.5rem;
  font-weight: 600;
}

button.button-primary-8w.button-primary-8w, .main-button {
  color: $color-black;
}

#cameraPermissionsErrorApple {
  div.permissionIconIos, div.bottom-message, p#cameraPermissionsErrorAppleMessage {
    display: none;
  }
}

.loading-error {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 802;
  background-color: $color-black;
  color: white;
  text-align: center;

  .inner-content {
    width: 80%;
  }

  .title, .subtitle {
    font-weight: 600;
  }

  .title {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .body {
    font-size: 0.9rem;
    margin: 2rem 0;
  }

  button.cta {
    border: none;
    border-radius: 999px;
    padding: 0.75rem 3rem;
    color: $color-primary !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    margin-bottom: 1rem;
    background-color: white;
  }
}

.recorder-container {
  z-index: 795;
  width: 22vw;
  height: 22vw;
  bottom: 6vmin;

  .progress-track {
    stroke-width: 1;
  }
}

.image-preview,
.video-preview {
  background-color: rgba(22, 22, 22, 0.75);

  .top-bar {
    height: 85vh;
  }

  .preview-box {
    @include flex;

    height: 100%;
  }
  
  #imagePreview {
    border-radius: 0;
    border: none;
    padding-top: 25%;
    padding-bottom: 0;
    background-color: transparent;
    height: 100%;
  }

  .bottom-bar {
    @include flex;

    height: 15vh;
  }

  #downloadButton, #actionButton {
    border: none;
    border-radius: 999px;
    padding: 0.75rem;
    height: auto;
    width: 75%;
    color: $color-primary !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    background-color: white;
    font-family: $typeface-primary;
    font-size: 1rem;
    position: relative;
    transform: none;
    top: initial;
    right: initial;
    margin: 0;

    img {
      display: none;
    }

    span {
      display: block;
      text-align: center;
      width: 100%;
    }
  }

  #downloadButton::after {
    content: 'Save';
    text-transform: uppercase;
  }

  #downloadButton {
    display: var(--displaysavebutton);
  }

  #closePreviewButton {
    opacity: 0;
  }

  #videoPreview {
    height: 60vh;
    margin-top: 20vmin;
    border-radius: 0;
    border: 0;
  }

  #toggleMuteButton {
    // @include flex;
  
    background-color: white;
    height: 12vmin;
    width: 12vmin;
    border: none;
    border-radius: 100%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    padding: 0;

    position: absolute;
    top: 5.33vmin;
    left: 100%;
    right: 5vmin;
    z-index: 99999999999999;

    &:before {
      display: block;
      content: "";
      background-repeat: no-repeat;
      background-position: 50% 50%;
      width: 100%;
      height: 100%;
    }

    &:has(img[src*="off"]) {
      &:before {
        background-image: url('assets/muted.svg');
      }
    }    
    
    &:has(img[src*="on"]) {
      &:before {
        background-image: url('assets/unmuted.svg');
      }
    }

    img {
      display: none;
    }
  }
}
