@use '../../styles/constants.scss' as *;

.menu {
  @include ui;

  top: initial;
  bottom: 0;
  height: 24.8vw;
  transition: transform 500ms cubic-bezier(0.16, 1, 0.3, 1);

  .svg-wrap {
    width: 100%;
    position: absolute;
    margin-bottom: -2%;
    left: 0;
    bottom: 0;
  }

  .options {
    @include flex(row, space-between);

    position: absolute;
    bottom: 0;
    left: 50%;
    width: 75%;
    height: 100%;
    transform: translateX(-50%);
  }

  .menu-option {
    @include flex(column);

    position: relative;
  }

  .option-icon {
    border: 1px solid white;
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem;
    padding: 0.4rem;
    transition: transform 0.1s linear;

    img {
      width: 100%;
    }

    &:active {
      transform: scale(0.9);
    }
  }

  .option-label {
    position: absolute;
    bottom: 0;
  }

  .spacer {
    opacity: 0;
  }

  .option-label, .spacer {
    color: white;
    font-size: 0.75rem;
    font-weight: 600;
  }
}

.page-holder {
  @include ui;
  
  height: 100vh;
  z-index: 796;
  overflow: hidden;
  background-color: $color-black;
}

.menu-page {
  @include ui;
  @include flex(column, flex-start);

  height: 100%;
  z-index: 880;
  background-color: $color-black;
  color: white;

  .menu-content {
    @include flex(column);
    margin: 0;
    width: 95%;
    transition: height 0.4s ease-out;
    font-size: 0.8rem;
  }

  .menu-title {
    color: #fae251;
    font-size: 1rem;
    margin: 1.5rem 1.5rem 0;
    text-align: center;
    line-height: 1.2;
  }

  .menu-location {
    margin: 0.5rem 1.5rem 1rem;
  }

  .menu-body {
    width: 100%;
    padding: 0 20vw;
  }

  .bold {
    font-weight: 700;
  }

  .superscript {
    font-size: 0.83em;
    vertical-align: super;
  }

  .menu-map {
    width: 100%;
    height: 100%;

    transition: height 0.4s ease-out;
  }
}
