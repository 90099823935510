@use '../../styles/index.scss' as *;

.instruction-display {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 120px;
  background-color: rgba(22, 22, 22, 0.65);
  backdrop-filter: blur(6px);
  z-index: 780;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.4s ease-out;

  &.visible {
    opacity: 1;
  }
  
  .text-contents {
    width: 80%;
  }

  .route-instructions {
    font-size: 1.25rem;
    width: 90%;
    line-height: 1.1;
  }

  .distance {
    display: inline-block;
    width: 80%;
    font-size: 0.8rem;
    margin-top: 5px;
  }

  .close-button {
    border: none;
    background-color: transparent;

    img {
      width: 7.5vmin;
      max-width: 50px;
    }
  }
}
