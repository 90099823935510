@use '../../styles/constants.scss';

.back-button {
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 0.8rem;
  height: 12vmin;
  display: flex;
  align-items: center;
  pointer-events: all;
}
