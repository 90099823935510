@use '../../styles/constants.scss' as *;

.experience-wrapper {
  opacity: 1;

  &--hidden {
    opacity: 0;
    position: absolute;
    top: 0;
    left: -50000;
  }
}

.experience-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1.4375rem;
  pointer-events: none;

  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.5));

  .title {
    font-family: $typeface-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    color: $color-white;
    margin: 0;
  }

  .built-by-rose {
    width: 4.375rem;
    display: block;
    height: 0.875rem;
  }
}