@use '../../styles/constants.scss' as *;

.audio-player {
  padding: 0 0.6875rem;
  display: flex;
  align-items: center;
  
  .player-button {
    background-color: transparent;
    width: 25px;
    height: 25px;
    box-shadow: none;

    img {
      width: 25px;
      height: 25px;
    }
  }

  .audio-progress {
    width: 100%;
    margin-left: 0.875rem;

    .rc-slider-rail {
      height: 3px;
    }

    .rc-slider-track {
      background-color: $color-secondary;
    }

    .rc-slider-handle {
      background-color: $color-secondary;
      opacity: 1;
      border: none;
    }

    .rc-slider-handle-dragging {
      box-shadow: 0 0 0 5px $color-secondary;
    }
  }
}