@use '../../styles/constants.scss' as *;

.route-map {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 779;
  height: 35%;
  width: 100vw;
  clip-path: circle(100% at 50% 150%);

  &--hidden {
    display: none;
  }

  .map {
    width: 100%;
    height: 100%;

    &--visible {
      animation: show 0.4s forwards;
    }

    &--hidden {
      animation: hide 0.4s forwards;
      pointer-events: none;
    }
  }
}

