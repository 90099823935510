@use '../../styles/constants.scss' as *;

.icon-button {
  .svg-wrapper {
    @include flex;
    
    width: 100%;
    height: 100%;
    pointer-events: all;
  }
}
