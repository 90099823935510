@use '../../styles/constants.scss' as *;

.reposition-overlay {
  @include ui;

  position: absolute;
  // top: 50%;
  top: auto;
  bottom: 30vmin;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 99;
  justify-content: center;
  display: flex;

  font-family: $typeface-primary;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: $color-white;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
}