@use '../../styles/constants.scss' as *;

.initial-message {
  @include ui;

  background: rgba(91, 66, 225, 0.65);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(6px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 12px;

  width: 85vw;

  top: 50%;
  left: 50%;
  padding: 20px;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  max-width: 400px;

  color: $color-white;

  font-family: $typeface-primary;
  font-style: normal;
  text-align: center;

  scale: 0;
  opacity: 0;
  transform-origin: 0 0;

  &--visible {
    animation: show 1s forwards;
  }  
  
  &--hidden {
    animation: hide 0.4s forwards;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;

    letter-spacing: 0.0115em;
  }

  .subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  .warning {
    font-size: 0.5rem;
    line-height: 0.75rem;
    text-align: center;
  }

  .button {
    padding: 10px;
    width: 230px;
    background: $color-white;
    border-radius: 24px;
    border-width: 0;
  
    font-family: $typeface-primary;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
  
    text-align: center;
    letter-spacing: 0.18em;
    text-transform: uppercase;
  
    color: $color-primary;
  }

  .built-by-rose {
    width: 6.25rem;
    margin-top: 0.6875rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}